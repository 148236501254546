<!-- eslint-disable max-len -->
<template>
  <div class="member_show">
    <div class="show_title">
      <img src="@/assets/img/account/icon_title_5.svg" alt="">
      <h4 class="txt-bold">我的優惠</h4>
    </div>

      <div class="show_discount">
        <div class="tagset">
          <router-link
            :to="{ name: 'MemberDiscount' }"
            class="tag active">
              <img src="@/assets/img/account/icon_coupon.svg" alt="">
              <p>折價券</p>
          </router-link>
          <!-- <router-link
            :to="{ name: 'MemberDiscountNoShip' }"
            class="tag">
              <img src="@/assets/img/account/icon_shippingfree.svg" alt="">
              <p>免運優惠</p>
          </router-link> -->
        </div>

        <div class="cart_head">
          <div class="cart_tr txt-grey">
            <div class="cart_td td-note"><small>折價券說明</small></div>
            <div class="cart_td td-date txt-center"><small>有效日期</small></div>
            <div class="cart_td td-worth txt-center"><small>折價券面額</small></div>
            <div class="cart_td td-btn txt-center"></div>
          </div>
        </div>

        <div class="cart_item_box">
          <div class="cart_item"
            v-for="(item, i) in coupon"
            :key="i">
              <div class="cart_tr">
                <div class="cart_td td-note">
                  <h6 class="note_name txt-bold">{{item.name}}</h6>
                  <p v-if="item.products">
                    適用商品：
                    <span
                      class="txt-blue" v-for="(pro, k) in item.products"
                      :key="k">
                        {{pro.product_name}}
                        <span v-if="k < item.products.length-1">,</span>
                    </span>
                  </p>
                  <p v-if="item.satisfy_amount">適用金額：<span class="txt-blue">${{item.satisfy_amount}}</span></p>
                  <p v-if="item.satisfy_qty">適用件數：<span class="txt-blue">{{item.satisfy_qty}}件</span></p>
                  <p v-if="item.satisfy_day">適用天數：<span class="txt-blue">{{item.satisfy_day}}天</span></p>
                  <!-- <a href="product.php" class="to_cart m1023-off txt-orange p">立即使用  <img src="@/assets/img/icon/icon_!-o.svg" alt="" style="width: .65em;"></a> -->
                </div>
                <div class="cart_td td-date center" data-rwd="有效日期" >
                  <div>
                    <p v-if="item.expire_day">{{item.expire_day}}</p>
                    <p v-else>-</p>
                  </div>
                </div>
                <div class="cart_td td-worth center" data-rwd="折價券面額" >
                  <p class="txt-redorange" v-if="item.discount_type == 1">${{item.discount}}</p>
                  <p class="txt-redorange" v-else-if="item.discount_type == 2">{{item.discount}}%</p>
                </div>
                <div class="cart_td td-btn txt-center">
                  <router-link
                    :to="{
                      name: 'Product',
                      params: { category: 1 },
                      query: { productCategory: 1, sort: 1, code: null },
                    }"
                    class="btn btn-cyan_line small">
                    立即使用
                  </router-link>
                </div>
                <!-- <a href="product.php" class="to_cart m1023-on txt-orange p" style="order:100; margin-top:10px;">立即使用  <img src="@/assets/img/icon/icon_!-o.svg" alt="" style="width: .65em;"></a> -->
              </div>
          </div>
        </div>

        <!-- <Paginate/> -->

      </div>

  </div>
</template>

<script>
// import Paginate from '@/components/Paginate.vue';
import { mapState } from 'vuex';
import requestApi from '@/lib/http';

export default {
  name: 'MemberDiscount',
  components: {
    // Paginate,
  },
  data() {
    return {
      coupon: [
      ],
    };
  },
  computed: {
    ...mapState([
      'user',
    ]),
  },
  methods: {
    async getCounty(type) {
      const { data } = await requestApi('member.getCoupons', {
        member_id: this.user.id,
        type,
      });

      data.forEach((element) => {
        console.log(element);
        this.coupon.push(element);
      });
    },
  },
  mounted() {
    this.coupon = [];
    this.getCounty(1);
    this.getCounty(2);
  },
};
</script>
